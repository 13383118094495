<template>
  <section class="mvbg">
    <div class="mmcc">
      <div class="container">
        <div class="mywrap w-100">
          <div class="conns">
            <h1>Sterling x Stears Energy Report</h1>
            <h4>
              Kindly fill the form below to download the Sterling and Stears
              Energy report
            </h4>
            <div class="conn">
              <a
                id="dlink"
                href="/assets/Sterling X Stears RE Report - The State of Power _June 2022.pdf"
                download
              ></a>
              <form action="">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fname">First Name</label>
                      <input
                        required
                        class="form-control shadow-none"
                        type="text"
                        id="fname"
                        @keyup="check"
                        v-model="fname"
                        name="fname"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="lname">Last Name</label>
                      <input
                        required
                        class="form-control shadow-none"
                        type="text"
                        id="lname"
                        @keyup="check"
                        v-model="lname"
                        name="lname"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="mail">Email</label>
                      <input
                        required
                        class="form-control shadow-none"
                        type="email"
                        @keyup="check"
                        v-model="email"
                        id="mail"
                        name="mail"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="sbtn"
                      :disabled="dis"
                      @click.prevent="downloadForm"
                    >
                      Download
                      <BtnLoading v-if="lod" class="btn-loading" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BtnLoading from "@/components/BtnLoading.vue";

export default {
  data() {
    return {
      dis: true,
      lod: false,
      fname: "",
      lname: "",
      email: "",
    };
  },
  components: { BtnLoading },
  methods: {
    check() {
      var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      // console.log(this.fname);
      // console.log(this.lname);
      // console.log(this.email);
      if (this.fname.length > 1) {
        // console.log("Fname passed");
        if (this.lname.length > 1) {
          // console.log("Lname Passed");

          if (this.email.match(validRegex)) {
            // console.log("Mail Passed");
            this.dis = false;
            return true;
          } else {
            this.dis = true;
            return false;
          }
        } else {
          this.dis = true;
          return false;
        }
      } else {
        this.dis = true;
        return false;
      }
    },
    downloadForm() {
      if (this.check()) {
        this.dis = true;
        this.lod = true;
        // console.log("Sending to DB");
        let bod = JSON.stringify({
          email: this.email,
          first_name: this.fname,
          last_name: this.lname,
        });

        var ebod = this.$helpers.encrypt(bod);

        // console.log(ebod);

        bod = JSON.stringify({ data: ebod });

        // console.log(bod);

        // console.log(process.env.VUE_APP_API_URL);
        // console.log(bod);
        fetch(`${process.env.VUE_APP_API_URL}/stears-energy-report`, {
          method: "post",
          body: bod,
          headers: {
            "Content-Type": "Application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data.data);
            data = this.$helpers.decrypt(data.data);
            data = JSON.parse(data);
            // console.log(data);

            if (data.status === "success") {
              // console.log(data);
              document.getElementById("dlink").click();
              this.lod = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.lod = false;
            this.dis = false;
          });

        // console.log(ch);
        // console.log(dl);
      }
    },
  },
};
</script>

<style scoped>
.mvbg {
  background-image: url("/assets/images/svgs/bannerimgcss.svg");
}
.mmcc {
  width: 100%;
  background-color: #f4fcf288;
}
.mywrap {
  min-height: 72vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.conns {
  /* padding: 30px 40px; */
  width: 80%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
}
.conn {
  background-color: #fafafa;
  color: #525f7f;
  padding: 30px 40px;
  width: 80%;
  margin: auto;
  text-align: left;
  border-radius: 20px;
  margin: auto;
}

h1 {
  color: #219653;
  font-weight: 500;
}
h4 {
  color: #202020;
  width: 50%;
  margin: auto;
  font-size: 1rem;
  margin-bottom: 32px;
}
input:focus {
  border: 1px solid #219653;
  background-color: #f4fcf21a;
}
.sbtn {
  width: 200px;
  border: none;
  background-color: #219653;
  color: #fafafa;
  font-weight: 500;
  padding: 12px 0;
  border-radius: 9px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  outline: none;
}
.sbtn:hover {
  background-color: #2196531a;
  color: #219653;
}
.sbtn:disabled {
  background-color: #2196538a;
  color: #fafafa;
  cursor: not-allowed;
}
a {
  display: none;
}

@media (max-width: 500px) {
  .conn {
    padding: 30px 19px;
    width: 100%;
  }
  h4 {
    width: 100%;
  }
  .conns {
    width: 95%;
  }
}
</style>